<template>
  <div>
    <!-- 油量查询 -->
    <ayl-berad-nav :nav="nav"></ayl-berad-nav>
    <div class="container-wrap">
      <div class="container" v-loading="loading">
        <div class="search-wrap">
          <div class="search-l">
            <span>车牌号：</span>
            <el-select
              v-model="deviceValue"
              filterable
              placeholder="请选择车牌号"
              @change="selectDevice"
            >
              <el-option
                v-for="item in deviceOptions"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              >
              </el-option>
            </el-select>
            <div style="margin-left: 20px">
              <span>日期：</span>
              <el-date-picker
                style="width: 310px"
                v-model="valueDate"
                type="datetimerange"
                :picker-options="pickerOptions"
                range-separator="-"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :default-time="['00:00:00', '23:59:59']"
                value-format="timestamp"
              >
              </el-date-picker>
              <el-tooltip class="item" effect="dark" placement="right">
                <div slot="content">
                  提示：默认或清空日期获取当前24小时的数据。
                </div>
                <i
                  style="margin-left: 8px; font-size: 16px; color: #333"
                  class="el-icon-info"
                ></i>
              </el-tooltip>
            </div>
            <!-- <el-button
              style="margin-left: 20px"
              type="primary"
              plain
              @click="onReport"
              >生成统计报告</el-button
            > -->
          </div>
          <div>
            <el-button type="primary" plain @click="onExport"
              >导出Excel</el-button
            >
          </div>
        </div>
        <div class="echarts-wrap">
          <div class="curr-data" v-if="currData.value" style="width: 380px">
            <div class="curr-title">实时油量</div>
            <div style="padding: 0 20px;box-sizing: border-box">
              <div class="line-data-box" style="line-height: 32px">
                <span class="line-data">油量</span>：
                <span
                  style="
                    flex: 1;
                    font-size: 24px;
                    font-weight: 700;
                    color: #2e3033;
                  "
                  >{{ currData.value }}L</span
                >
              </div>
              <div class="line-data-box">
                <span class="line-data">时间</span>：
                <span style="flex: 1">{{ currData.time }}</span>
              </div>
              <div class="line-data-box">
                <span class="line-data">位置</span>：
                <span style="flex: 1">{{ currData.position }}</span>
              </div>
            </div>
          </div>
          <div v-else class="curr-data curr-data-null">暂无数据</div>
          <div class="echarts-list">
            <div ref="linechart" style="width: 100%; height: 400px"></div>
          </div>
        </div>
        <!-- 表格 -->
        <div>
          <ayl-new-table :data="tableData"> </ayl-new-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import echarts from "echarts";
import resize from "./resize";
export default {
  name: "SewageQuerys",
  components: {},
  mixins: [resize],
  data() {
    let vm = this;
    return {
      nav: [{ name: "油量查询" }],
      pickerOptions: {
        onPick(time) {
          //当第一时间选中才设置禁用
          if (time.minDate && !time.maxDate) {
            vm.selectDate = time.minDate;
          }
          if (time.maxDate) {
            vm.selectDate = null;
          }
        },
        disabledDate(time) {
          return vm.dealDisabledDate(time);
        },
        shortcuts: [
          {
            text: "近24h",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 1);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近一周",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "近一个月",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      loading: false,
      unit: null,
      deviceOptions: [],
      deviceValue: null,
      sensorName: null,
      valueDate: [
        new Date().getTime() - 3600 * 1000 * 24 * 1,
        new Date().getTime(),
      ],
      currData: {},

      tableData: {
        api: null,
        query: {},
        columns: [
          {
            title: "序号",
            type: "index",
            align: "center",
            showTooltip: true,
          },
          {
            title: "车牌号码",
            key: "plateNumber",
            width: "120px",
            showTooltip: true,
          },
          {
            title: "时间",
            key: "time",
            filter: "str2ymdhm",
            width: "180px",
            align: "center",
            sortable: true,
            showTooltip: true,
          },
          {
            title: "当时油量(L)",
            key: "oil",
            width: "180px",
            showTooltip: true,
          },
          {
            title: "当时里程(KM)",
            key: "mileage",
            width: "180px",
            showTooltip: true,
          },
          {
            title: "当时速度(KM/h)",
            key: "speed",
            width: "180px",
            showTooltip: true,
          },
          {
            title: "当时位置",
            key: "location",
            showTooltip: true,
          },
          {
            title: "当时状态",
            key: "accStatus",
            width: "180px",
            align: "center",
            showTooltip: true,
          },
        ],
        data: [],
      },
    };
  },
  watch: {
    deviceValue() {
      if (this.deviceValue) {
        this.getRealTimeOilData();
        this.getSensorData();
      }
    },
    valueDate() {
      if (this.deviceValue) {
        this.getRealTimeOilData();
        this.getSensorData();
      }
    },
  },
  mounted() {
    this.echartsInit([], []);
    // 获取设备下拉数据
    this.$api.oilQuery_getHasFuelPlateNumberList({}).then((res) => {
      if (res.length === 0 || res == null) return;
      this.deviceOptions = res.map((item) => {
        return {
          value: item,
          label: item,
        };
      });
      this.deviceValue = this.deviceOptions
        ? this.deviceOptions[0].value
        : null;
    });
  },
  methods: {
    // 日期区间
    dealDisabledDate(time) {
      if (this.selectDate) {
        return (
          time.getTime() - 3600 * 1000 * 24 * 30 > this.selectDate ||
          time.getTime() + 3600 * 1000 * 24 * 30 < this.selectDate
        );
      } else {
        return false;
      }
    },
    selectDevice(val) {
      // console.log(val);
    },
    echartsInit(listX, listY) {
      this.chart = echarts.init(this.$refs.linechart);
      let option = {
        // backgroundColor: '#f5f7fa',
        xAxis: {
          type: "category",
          data: listX,
          axisLabel: {
            margin: 20,
          },
        },
        yAxis: {
          type: "value",
          axisLabel: {
            formatter: `{value}  ${this.unit}`,
          },
        },
        toolbox: {
          feature: {
            restore: {},
            saveAsImage: {},
          },
        },

        tooltip: {
          trigger: "axis",
        },
        grid: {
          top: 50,
          left: 100,
          right: 20,
          bottom: 90,
        },
        // 底部展开
        dataZoom: [
          {
            type: "inside",
            start: 0,
            end: 20,
          },
          {
            start: 0,
            end: 20,
          },
        ],
        series: [
          {
            name: `${this.sensorName}  `,
            data: listY,
            type: "line",
            smooth: true,
            symbolSize: 6,
          },
        ],
      };
      option && this.chart.setOption(option);
    },
    getRealTimeOilData() {
      let paramas = {
        plateNumber: this.deviceValue,
      };
      this.$api.oilQuery_getRealTimeOilData(paramas).then((res) => {
        this.currData = {
          value: res.oil,
          position: res.position,
          time: res.time,
        };
      });
    },

    // 获取图标数据
    getSensorData() {
      let paramas = {
        plateNumber: this.deviceValue,
        startTime: this.valueDate
          ? this.valueDate[0]
          : new Date().getTime() - 3600 * 1000 * 24,
        endTime: this.valueDate ? this.valueDate[1] : new Date().getTime(),
      };
      this.loading = true;
      this.$api
        .oilQuery_queryOilData(paramas)
        .then((res) => {
          this.sensorName = res.length > 0 ? "当时油量(L)" : "";
          this.unit = res.length > 0 ? "L" : "暂无";
          this.listX = res.map((i) => i.time);
          this.listY = res.map((i) => i.oil);
          this.echartsInit(this.listX, this.listY);
          this.tableData.data = res;
          this.loading = false;
        })
        .catch((err) => {
          this.loading = false;
        });
    },

    // 导出
    onExport() {
      let params = {
        excelKey: "exportQueryOilData",
        indexList: [0, 1, 2, 3, 4, 5, 6],
        params: {
          plateNumber: this.deviceValue,
          startTime: this.valueDate
            ? this.valueDate[0]
            : new Date().getTime() - 3600 * 1000 * 24,
          endTime: this.valueDate ? this.valueDate[1] : new Date().getTime(),
        },
      };
      this.$api.ListOilOverviewExcel(params).then((res) => {
        window.open(res);
      });
    },
    // 生成统计报告
    // onReport() {
    //   let params = {
    //     id: this.deviceValue,
    //     startTime: this.valueDate
    //       ? this.valueDate[0]
    //       : new Date().getTime() - 3600 * 1000 * 24,
    //     endTime: this.valueDate ? this.valueDate[1] : new Date().getTime(),
    //   };
    //   this.$api_hb.hb_system_management__downEnvPdf(params).then((res) => {
    //     console.log(res);
    //     window.open(res);
    //   });
    // },
  },
};
</script>

<style lang="css" scoped>
.container-wrap {
  height: calc(100% - 34px);
  padding: 20px;
  box-sizing: border-box;
  overflow-y: scroll;
}
.container {
  min-height: 100%;
  background-color: #fff;
  padding: 18px;
  box-sizing: border-box;
}

.search-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.search-l {
  flex: 1;
  display: flex;
  align-items: center;
}

.echarts-wrap {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  padding: 18px;
  width: 100%;
  /* border: 1px dashed rgb(216, 210, 210); */
  box-shadow: 0 0 3px rgb(50 50 50 / 20%);
  /* box-sizing: border-box;
  background-color: #ebeff391; */
}
.curr-data {
  padding: 20px 0;
  width: 380px;
  height: 300px;
  box-sizing: border-box;
  background-color: #f5f7fa;
  
}
.curr-title {
  padding-bottom: 15px;
  margin-bottom: 50px;
  text-align: center;
  border-bottom: 1px solid #ddd;
}
.line-data-box {
  display: flex;
  margin-bottom: 10px;
  line-height: 1.5;
}
.line-data {
  width: 60px;
  text-align: right;
}
.curr-data-null {
  display: flex;
  justify-content: center;
  align-items: center;
}
.echarts-list {
  flex: 1;
}
</style>
